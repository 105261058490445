<template src="./index.html"> </template>

<script>
import { mapState, mapActions } from 'vuex'
import Banner from '_c/banner/Index'
import MainListV2 from '_c/mainlistv2/Index'
import CategorysBoxV2 from '_c/CategorysBoxV2'
import ContactTip from '_c/ContactTip'

const SHORTFIELD_SOLUTION_TYPE = 'tid'
const SHORTFIELD_SCENE_TYPE = 'stid'

export default {
  async asyncData ({ store, route }) {
    const PAGE = store.state.commonData.pageConfig['solution']
    const DISPATCH = store.dispatch
    const QUERY = store.state.route.query
    const CURRENTPAGE = store.state.route.name

    return await Promise.all([
      DISPATCH('componentCategorys/setSolutionTypeId',{
        solutionTypeId : +QUERY[SHORTFIELD_SOLUTION_TYPE]
      }),
      DISPATCH('componentCategorys/setSceneTypeId',{
        sceneTypeId : +QUERY[SHORTFIELD_SCENE_TYPE]
      }),
      DISPATCH('commonData/setCurrentPage', CURRENTPAGE),
      DISPATCH('componentCategorys/getCategorysV2'),
      DISPATCH('dataSolution/getChosenList', {
        offset: 0,
        limit: PAGE.chosenLimit
      }),
      DISPATCH('dataSolution/getMainList', {
        offset: 0,
        limit: PAGE.mainLimit,
        name: QUERY.search || '',
        solutionType : +QUERY[SHORTFIELD_SOLUTION_TYPE] || 0,
        sceneType : +QUERY[SHORTFIELD_SCENE_TYPE] || 0
      })
    ])
  },
  components:{
    Banner,
    MainListV2,
    CategorysBoxV2,
    ContactTip,
  },
  computed: {
    ...mapState('componentCategorys', {
      solutionTypeList: s => s.solutionTypeList || [],
      sceneTypeList: s => s.sceneTypeList || []
    }),
    dataFields() {
      let obj = [
        {
          field: 'solutionTypeId',
          shortField: SHORTFIELD_SOLUTION_TYPE,
          name: '方案类型',
          options: this.solutionTypeList
        },
        {
          field: 'sceneTypeId',
          shortField: SHORTFIELD_SCENE_TYPE,
          name: '应用场景',
          options: this.sceneTypeList
        }
      ]
      return obj
    }
  }
}
</script>
